.pricing {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  background: $color--modal;

  @include breakpoint (medium) {
    background-position: top;
    background-size: cover;
    height: 100vh;
    background: unset;
  }

  @include breakpoint (large) {
    background-position: bottom;
    background-size: cover;
  }

  &__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include breakpoint(medium) {
      margin-top: 60px;
    }
  }


  &__inner {
    z-index: 2;
    text-align: center;
    font-weight: 100;
    width: 90%;
    background: $color--modal;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @include breakpoint (medium) {
      margin-top: 50px;
      justify-content: center;
      align-items: center;
      max-width: $content-width;
      padding: 12px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    }


    .prices-company {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 16px 16px;
      overflow: scroll;

      @include breakpoint(medium) {
        padding: 50px 50px;
        overflow: unset;
      }

      .pricing-plan {
        background: $color--dark;
        padding: 30px 20px;
        border-radius: 15px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        transition: 0.3s ease;
        min-width: 300px;
        margin: 20px;
        position: relative;
        min-height: 470px;


        .plan-icon {
          position: absolute;
          height: 60px;
          width: 60px;
          left: calc(50% - 30px);
          top: -30px;
          border-radius: 30px;
          background-color: $color--list;
          flex-direction: column;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 30px;
            width: 30px;
            margin: 0;
          }
        }

        @include breakpoint (medium) {
          min-width: unset;
          width: 23%;
          margin: unset;
        }

        &:first-of-type {
          margin: 20px 20px 20px 0;

          @include breakpoint(medium) {
            margin: unset;
          }
        }

        &:last-of-type {
          margin: 20px 0 20px 20px;

          @include breakpoint(medium) {
            margin: unset;
          }
        }

        &:hover {
          background: $color--list-hover;
        }

        &-inner {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .price-heading {
          padding-bottom: 15px;
          border-bottom: 2px solid $color--list;
          width: 100%;
          margin-top: 30px;
        }

        ul {
          margin: 0;
          list-style: none;
          margin-bottom: 40px;

          li {
            font-size: 18px;
          }
        }

        .price {
          font-weight: bold;
          font-family: $font-body;
          font-size: 26px;
        }

        .period-div {
          font-size: 18px;
          line-height: 18px;
          color: grey;
        }

        .cta {
          margin-top: 20px;
          padding: 12px 24px;
          transition: 1s ease;

          &:hover {
            background: $color--green !important;
          }
        }
      }
    }
  }
}