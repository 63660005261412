.upload-file-modal {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 10;

  .upload-file-modal__inner {
    position: absolute;
    left: calc(50% - 175px);
    top: calc(50% - 150px);
    height: 100%;
    max-height: 300px;
    width: 100%;
    max-width: 350px;
    padding: 12px 24px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: $color--modal;

    @include breakpoint(medium) {
      padding: 12px 24px;
      position: absolute;
      max-width: 500px;
      left: calc(50% - 250px);
      top: calc(50% - 150px);
    }

    .spinner {
      height: 64px;
      align-items: center;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 10%;

      .lds-dual-ring {
        display: inline-block;
        width: 50px;
        height: 50px;
        align-items: center;
        margin-bottom: 4px;
      }

      .lds-dual-ring:after {
        content: " ";
        display: block;
        width: 50px;
        height: 50px;
        margin-top: 7px;
        border-radius: 50%;
        border: 5px solid #DDDFE6;
        border-color: #DDDFE6 transparent #DDDFE6 transparent;
        animation: lds-dual-ring 1.2s linear infinite;
        align-items: center;
      }

      @keyframes lds-dual-ring {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }

    .upload-file-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      input {
        color: $color--white;
      }

      input[type="file"]::file-selector-button {
        border-radius: 4px;
        padding: 0 16px;
        height: 40px;
        cursor: pointer;
        background-color: white !important;
        border: 1px solid rgba(0, 0, 0, 0.16);
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        margin-right: 16px;
        transition: background-color 200ms;
        color: black;
      }

      /* file upload button hover state */
      input[type="file"]::file-selector-button:hover {
        background-color: #f3f4f6 !important;
      }

      /* file upload button active state */
      input[type="file"]::file-selector-button:active {
        background-color: #e5e7eb;
      }
    }

    img {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 35px;
      width: 35px;
    }

    h2 {
      margin-bottom: 30px;
    }

    .button {
      margin-top: 0;
      color: white;
      width: -moz-fit-content;
      width: fit-content;
      padding: 12px 24px;
      border-radius: 25px;
      transition: 0.3s ease-in;
      font-size: 16px;
      text-transform: uppercase;
      border: none;

      &.disabled {
        background: grey !important;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

        &:active {
          transform: none;
        }
      }
    }
  }


  .background {
    position: absolute !important;
    background-color: black;
    opacity: 0.5 !important;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
}