.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.container {
  position: relative;
  width: 90%;
  height: 680px;
  display: flex;
  flex-direction: column;
  background: $color--modal;
  padding: 24px 12px;
  border-radius: 15px;

  @include breakpoint(small) {
    flex-direction: column;
    width: 768px;
    height: 480px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);
  }

  .overlay-container {
    position: absolute;
    left: 0%;
    width: 100%;
    height: 50%;
    top: 0;
    overflow: hidden;
    transition: transform 0.5s ease-in-out;
    z-index: 100;

    @include breakpoint(small) {
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;
      overflow: hidden;
      transition: transform 0.5s ease-in-out;
      z-index: 100;
    }
  }

  .overlay {
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    background: $color--green-blue-gradient;
    color: #fff;
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
  }

  @mixin overlays($property) {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 70px 40px;
    width: 50%;
    height: 100%;
    text-align: center;
    transform: translateX($property);
    transition: transform 0.5s ease-in-out;
  }

  .overlay-left {
    @include overlays(-20%);
  }

  .overlay-right {
    @include overlays(0);
    right: 0;
  }
}

p {
  margin: 20px 0 30px;
}

a {
  color: #222;
  text-decoration: none;
  margin: 15px 0;
  font-size: 1rem;
}

// button {
//   border-radius: 20px;
//   border: 1px solid $color--dark;
//   background-color: $color--dark;
//   color: #fff;
//   font-size: 1rem;
//   font-weight: bold;
//   padding: 10px 40px;
//   letter-spacing: 1px;
//   text-transform: uppercase;
//   cursor: pointer;
//   transition: transform 0.1s ease-in;

//   &:active {
//     transform: scale(0.9);
//   }

//   &:focus {
//     outline: none;
//   }
// }

button.invert {
  border-color: #fff;
}

.auth-blocks {
  height: 100%;
  display: flex;
  flex-direction: column;

  @include breakpoint(small) {
    flex-direction: column;
  }

  .sign-in-container,
  .sign-up-container {
    height: 50%;

    .loader {
      width: fit-content;
      margin: 0;
      font-family: $font-subheader;
      font-size: 30px;
      clip-path: inset(0 1ch 0 0);
      animation: l4 1s steps(4) infinite;
      height: 30px !important;
      margin-bottom: 12px;
    }

    .loader:before {
      content: "Signing In...";
      font-size: 25px;
      line-height: 30px;
      font-family: $font-header;
      height: 30px !important;
    }

    @keyframes l4 {
      to {
        clip-path: inset(0 -1ch 0 0)
      }
    }
  }
}

form {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  transition: all 0.5s ease-in-out;

  @include breakpoint(small) {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 90px 60px;
    width: 50%;
    height: 100%;
    text-align: center;
    background: $color--modal !important;
    transition: all 0.5s ease-in-out;
  }

  div {
    font-size: 1rem;
  }

  .form-control {
    max-width: 200px;
    border: none;
  }

  .success-message,
  .server-error {
    font-size: 12px;
    max-width: 200px;
    color: red;
  }

  input {
    border: none;
    padding: 8px 15px;
    margin: 6px 0;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;

    &:focus {
      outline: none;
    }
  }
}

.sign-up-active {
  .sign-in {
    @include breakpoint(small) {
      transform: translateX(100%);
    }
  }

  .sign-up {
    @include breakpoint(small) {
      transform: translateX(100%);
      opacity: 1;
      z-index: 5;
      animation: show 0.5s;
    }
  }

  .overlay-container {
    transform: translateY(100%);

    @include breakpoint(small) {
      transform: translateX(-100%);
    }
  }

  .overlay {
    transform: translateX(50%);
  }

  .overlay-left {
    transform: translateX(0);
  }

  .overlay-right {
    transform: translateX(20%);
  }
}

@keyframes show {
  0% {
    opacity: 0;
    z-index: 1;
  }

  49% {
    opacity: 0;
    z-index: 1;
  }

  50% {
    opacity: 1;
    z-index: 10;
  }
}