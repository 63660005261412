.role {
  width: 100%;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: $color--black;

    @include breakpoint (medium) {
      height: fit-content;
    }

    h2 {
      margin: 0;
    }

    h3 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;

      @include breakpoint (small) {}

      @include breakpoint (medium) {
        font-size: 22px;
        line-height: 26px;
      }
    }

    h4 {
      font-size: 22px;
      line-height: 26px;
      margin: 0;
      margin-bottom: 0;
      font-family: $font-body;
      font-weight: bold;
      letter-spacing: 3px;

      span {
        display: none;

        @include breakpoint(small) {
          display: contents;
        }
      }


      @include breakpoint (small) {}

      @include breakpoint (medium) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    h5 {
      font-size: 16px;
      line-height: 18px;
      margin: 0;
      margin-bottom: 30px;
      font-family: $font-body;
      letter-spacing: 2px;


      @include breakpoint (small) {}

      @include breakpoint (medium) {
        font-size: 16px;
        line-height: 30px;
      }
    }

    h4,
    h5 {
      text-align: right;
      color: $color--white;
      margin: 0;
    }

    .roles-list {
      position: relative;
      width: 100%;
      height: 280px;
      overflow: scroll;
      background: $color--list;
      margin: 0;
      margin-bottom: 20px;
      border-radius: 0px 0px 10px 10px;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

      @include breakpoint(medium) {
        height: 250px;
        display: unset;
      }

      li {
        margin: 0;
        color: $color--white;
        padding: 8px 12px;
        display: flex;
        width: 100%;
        left: 40%;
        align-items: center;
        height: 54px;

        @include breakpoint(medium) {
          height: 50px;
        }

        &:nth-child(2n) {
          background: $color--list-2n;
        }

        &:hover {
          background: $color--list-hover;
          transition: 0.3s ease-in-out;
        }
      }

      .spinner-div {
        height: 100%;
        align-items: center;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 5%;

        .lds-dual-ring {
          display: inline-block;
          width: 50px;
          height: 50px;
          align-items: center;
          margin-bottom: 4px;
        }

        .lds-dual-ring:after {
          content: " ";
          display: block;
          width: 50px;
          height: 50px;
          margin-top: 7px;
          border-radius: 50%;
          border: 5px solid #fff;
          border-color: #fff transparent #fff transparent;
          animation: lds-dual-ring 1.2s linear infinite;
          align-items: center;
        }

        @keyframes lds-dual-ring {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    .options {
      height: 34px;
      width: 34px;

      @include breakpoint(medium) {
        height: 30px;
        width: 30px;
        width: 10%;
      }

      button {
        background: none;
        border: none;
        padding: 0;
        transition: transform 0.1s ease-in;

        .bin {
          border-radius: 18px;
          height: 34px;
          width: 34px;
          padding: 6px;
          box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
          background: $color--purple;

          @include breakpoint(medium) {
            border-radius: 15px;
            height: 30px;
            width: 30px;
          }

        }
      }
    }

    .role-name {
      width: calc(100% - 68px);

      @include breakpoint(medium) {
        width: calc(100% - 180px);
      }
    }

    .add-button-div {
      position: relative;
      width: 100%;

      .add-role {
        position: absolute;
        color: white;
        width: 50px;
        height: 50px;
        padding: 0;
        border-radius: 25px;
        transition: 0.3s ease-in;
        font-size: 16px;
        text-transform: uppercase;
        border: none;
        margin-bottom: 10px;
        right: 10px;
        top: -80px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        transition: transform 0.1s ease-in;
        background: #695dff;

        img {
          width: 30px;
          height: 30px;
        }

        &:hover {
          transform: scale(1.1);
        }

        &:active {
          transform: scale(0.9);
        }

        &:focus {
          outline: none;
        }
      }
    }

    .selected {
      background: $color--green-blue-gradient !important;
      transition: 0.3s ease-in-out;
    }

    .card-heading {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 0px;
      flex-direction: row-reverse;
      width: 100%;
      padding: 20px 10%;
      background: $color--heading;
      border-radius: 10px 10px 0 0;
      font-size: 24px;
    }

    .button.next {
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      margin-left: auto;
      transition: transform 0.1s ease-in;
    }

    .img-background {
      background: linear-gradient(to bottom right, #FF7474, #20222a);
      border-radius: 50%;
      width: 45px;
      height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;

    .col-md-auto {
      width: 120px;
    }

    .button.next.disabled {
      background: grey !important;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
  }
}