$color--dark: #282E38;
$color--modal: #1D222B;
$color--heading: #282E38;
$color--green: #0EFEA0;
$color--blue: #17B5FC;
$color--purple: #695dff;
$color--list: #323a49;
$color--list-2n: #2C313E;
$color--list-hover: #3c4659;
$color--green-blue-gradient: linear-gradient(to bottom right, #0EFEA0, #187dd8);
$color--purple-blue-gradient: linear-gradient(to bottom right, #17B5FC, #695dff);
$color--green-purple-gradient: linear-gradient(to bottom right, #0EFEA0, #695dff);
$color--grey-white-gradient: linear-gradient(to bottom right, grey, darkgrey);


$color--light-blue: #92A8D1;
$color--yellow: #E8B832;
$color--red: #955251;
$color--grey: #363945;
$color--white: #ffffff;
$color--black: #000000;

$font-header: 'Muli';
$font-subheader: 'LibreFranklin', 'sans-serif';
$font-body: 'Harmattan';


$color--font-primary: $color--white;
$color--font-secondary: $color--black;

$color--primary: $color--dark;
$color--secondary: $color--light-blue;
$color--tertiary: $color--grey;

$smaller-width: 1140px;
$content-width: 1140px;
$full-width: 1480px;

@import 'global/mixins';
@import 'global/normalize';
@import 'global/fonts';
@import 'global/base';
@import 'global/buttons';
@import 'global/form-control';

@import 'components/header';
@import 'components/footer';
@import 'components/offCanvas';
@import 'components/banner';
@import 'components/canvas';
@import 'components/formatter';
@import 'components/login';
@import 'components/cards/resume';
@import 'components/cards/template';
@import 'components/cards/confirm';
@import 'components/cards/role';
@import 'components/modals/addRoleModal';
@import 'components/modals/uploadFileModal';
@import 'components/modals/errorModal';
@import 'components/modals/templateStylingModal';
@import 'components/verify-email';
@import 'components/pricing';