.confirm {

  .button-container {
    display: flex;
    justify-content: flex-end;

    .col-md-auto {
      width: 120px;
    }
  }

  .link-container {
    width: 100%;
    margin-top: 40px;
  }

  .link-text {
    margin: 10px;
  }

  .button {
    background: $color--purple !important;
    color: $color--white;
    width: fit-content !important;
    height: fit-content !important;
    padding: 16px 32px;
    border-radius: 30px;
    transition: 0.3s ease-in-out;
    text-transform: capitalize;
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    font-weight: bold;
    letter-spacing: 1.1px;

    &:hover {
      transform: scale(1.1);
    }


    &:active {
      transform: scale(0.9);
    }

    &:focus {
      outline: none;
    }
  }

  .spinner-div {
    opacity: 0.75;
    height: 40px;
    max-width: 300px;
    padding: 3px 3px 1px 3px;
    align-items: center;
    display: flex;
    border-radius: 24px;
    width: 100%;

    &:before {
      content: "";
      display: inline-block;
      height: 40px;
      max-width: 300px;
      width: 100%;
      border-radius: 30px;
      box-shadow: inset 0 20px 0 rgba(255, 255, 255, 0.2);

      background-color: #695DFF;
      background-size: 50px 50px;
      background-image: linear-gradient(-45deg,
          #eee 25%,
          transparent 25%,
          transparent 50%,
          #eee 50%,
          #eee 75%,
          transparent 75%,
          transparent);
      animation: move 1s linear infinite;
    }
  }
}