.template-styling-modal {

  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 10;

  &__inner {
    position: absolute;
    left: calc(50% - 175px);
    top: calc(50% - 300px);
    height: 100%;
    max-height: 700px;
    width: 100%;
    max-width: 350px;
    padding: 12px 24px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: $color--modal;

    form {
      width: 100%;
      padding: 70px 30px;

      .form-control {
        width: 100%;
        max-width: none;

        label {
          width: 100%;
        }

        input {
          margin-bottom: 20px;
          width: 100%;
          text-align: center;
        }

        select {
          margin-bottom: 20px;
          width: 100%;
          text-align: center;
        }

        label {
          margin-bottom: 4px;
        }

        input[type=checkbox] {
          height: 20px;
          width: 20px;
        }

        input[type=checkbox]:checked:after {
          background-color: $color--purple;
        }
      }
    }

    img {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 35px;
      width: 35px;
      z-index: 10;
    }
  }

  .background {
    position: absolute !important;
    background-color: black;
    opacity: 0.5 !important;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
}