.add-role-modal {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100dvh;
  z-index: 10;

  .add-role-modal__inner {
    position: absolute;
    left: 10%;
    top: 80px;
    height: calc(100dvh - 160px);
    width: 80%;
    padding: 6px 12px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: $color--modal;

    @include breakpoint(small) {
      padding: 12px 24px;
    }

    @include breakpoint(medium) {
      width: 40%;
      padding: 12px 24px;
      left: 30%;
      height: 80%;
      top: 120px;
    }

    img {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 35px;
      width: 35px;
    }

    .form-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      form {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border: none;
        padding: 0px;
        width: 90%;
        height: unset;
        border-radius: 15px;
        background-color: transparent;
        overflow: scroll;

        input {
          margin-top: 10px;
        }
      }
    }

    .alert-box {
      position: relative;
      width: 100%;

      img {
        position: absolute;
        top: 16px;
        margin: 0;
        padding: 0;
        height: 30px;
        width: 30px;
      }
    }

    h2 {
      text-align: center;
      color: $color--white;
    }


    // button {
    //   margin-top: 16px;
    //   width: -moz-fit-content;
    //   width: fit-content;
    //   padding: 12px 24px;
    //   border-radius: 25px;
    //   transition: 0.3s ease-in;
    //   font-size: 16px;
    //   text-transform: uppercase;
    //   border: none;
    // }


  }

  .background {
    position: absolute !important;
    background-color: black;
    opacity: 0.5 !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}