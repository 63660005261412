.resume {
  width: 100%;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 0px 10px 10px 0px;

    @include breakpoint (medium) {
      height: fit-content;
    }

    input[type=file]::file-selector-button {
      background: $color--grey;
      color: white;
      width: fit-content;
      padding: 12px 24px;
      border-radius: 12px;
      transition: 0.3s ease-in-out;
      text-transform: capitalize;
      font-size: 16px;
      text-transform: uppercase;
      border: none;
      background: $color--dark;
      margin-bottom: 20px;

      &:hover {
        background: #8B8785;
      }

      &:active {
        transform: scale(0.9);
      }

      &:focus {
        outline: none;
      }
    }

    h3 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;

      @include breakpoint (small) {}

      @include breakpoint (medium) {
        font-size: 22px;
        line-height: 26px;
      }
    }

    h4 {
      font-size: 22px;
      line-height: 26px;
      margin: 0;
      margin-bottom: 0;
      font-family: $font-subheader;
      font-weight: bold;


      @include breakpoint (small) {}

      @include breakpoint (medium) {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 2px;
      }


      span {
        display: none;

        @include breakpoint(small) {
          display: contents;
        }
      }
    }

    h5 {
      font-size: 16px;
      line-height: 18px;
      margin: 0;
      margin-bottom: 30px;
      font-family: $font-header;


      @include breakpoint (small) {}

      @include breakpoint (medium) {
        font-size: 16px;
        line-height: 30px;
      }
    }


    h4 {
      font-family: "Muli";
    }

    h4,
    h5 {
      margin: 0px;
      text-align: center;
      color: $color--white;
      letter-spacing: 2px;
    }

    .resume-list {
      position: relative;
      width: 100%;
      height: 280px;
      overflow: scroll;
      background: rgba(28, 41, 81, 0.15);
      background: #DDDFE6;
      margin: 0;
      margin-bottom: 20px;
      border-radius: 0px 0px 10px 10px;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      background: $color--list;

      @include breakpoint(medium) {
        height: 250px;
      }

      li {
        margin: 0;
        padding: 8px 12px;
        display: flex;
        width: 100%;
        left: 40%;
        align-items: center;
        height: 54px;
        display: flex;


        &:nth-child(2n) {
          background: $color--list-2n;
        }

        &:hover {
          background: $color--list-hover;
          transition: 0.3s ease-in-out;
        }

        .bin {
          border-radius: 15px;
          height: 30px;
          width: 30px;
          padding: 4px;
          background: $color--purple-blue-gradient;
          box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
          transition: transform 0.1s ease-in;
        }

        span {

          @include breakpoint(small) {
            width: 90px;
          }

          span {
            svg {
              height: 30px;
              width: 30px;
            }
          }
        }

        @keyframes move {
          100% {
            background-position: 50px 50px;
          }
        }

        body#body * {
          box-sizing: border-box;
        }

        .spinner-div {
          opacity: 0.75;
          height: 33px;
          max-width: 440px;
          padding: 3px 3px 1px 3px;
          margin: 100px auto;
          align-items: center;
          display: flex;
          border-radius: 24px;
          width: 70px;

          &:before {
            content: "";
            display: inline-block;
            height: 16px;
            width: 70%;
            border-radius: 30px;
            box-shadow: inset 0 20px 0 rgba(255, 255, 255, 0.2);

            background-color: $color--purple;
            background-size: 50px 50px;
            background-image: linear-gradient(-45deg,
                #eee 25%,
                transparent 25%,
                transparent 50%,
                #eee 50%,
                #eee 75%,
                transparent 75%,
                transparent);
            animation: move 1s linear infinite;
          }
        }

        @keyframes animatedBackground {
          from {
            background-position: 0 0;
          }

          to {
            background-position: -100% 0;
          }
        }
      }


      .spinner {
        height: 100%;
        align-items: center;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 5%;

        .lds-dual-ring {
          display: inline-block;
          width: 50px;
          height: 50px;
          align-items: center;
          margin-bottom: 4px;
        }

        .lds-dual-ring:after {
          content: " ";
          display: block;
          width: 50px;
          height: 50px;
          margin-top: 7px;
          border-radius: 50%;
          border: 5px solid #fff;
          border-color: #fff transparent #fff transparent;
          animation: lds-dual-ring 1.2s linear infinite;
          align-items: center;
        }

        @keyframes lds-dual-ring {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    .options {
      height: 34px;
      width: 34px;

      @include breakpoint(medium) {
        height: 30px;
        width: 30px;
        width: 10%;
      }

      button {
        background: none;
        border: none;
        padding: 0;
        transition: transform 0.1s ease-in;
        width: 34px;
        height: 34px;

        .bin {
          border-radius: 18px;
          height: 34px;
          width: 34px;
          padding: 6px;
          box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
          background: $color--purple;

          @include breakpoint(medium) {
            border-radius: 15px;
            height: 30px;
            width: 30px;
          }

        }
      }
    }

    .resume-name {
      width: 80%;
    }

    .add-button-div {
      position: relative;
      width: 100%;

      .add-resume {
        position: absolute;
        color: white;
        width: fit-content;
        width: 50px;
        height: 50px;
        padding: 0;
        border-radius: 25px;
        transition: 0.3s ease-in;
        font-size: 16px;
        text-transform: uppercase;
        border: none;
        margin-bottom: 10px;
        right: 10px;
        top: -80px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        transition: transform 0.1s ease-in;
        background: $color--purple;

        img {
          height: 30px;
          width: 30px;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .selected {
      background: $color--green-blue-gradient !important;
      transition: 0.3s ease-in-out;
    }

    .card-heading {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0px;
      flex-direction: row-reverse;
      width: 100%;
      padding: 20px 10%;
      border-radius: 10px 10px 0 0;
      font-size: 24px;
      background: $color--heading;
    }

    .button.next {
      margin-left: auto;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      transition: transform 0.1s ease-in;
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;

    .col-md-auto {
      width: 120px;
    }

    .button.next.disabled {
      background: grey !important;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
  }
}