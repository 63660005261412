@font-face {
  font-family: 'LibreFranklin';
  src: url("../../fonts/LibreFranklin-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src:
    url("../../fonts/Muli-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Harmattan";
  src:
    url("../../fonts/Harmattan-Regular.ttf") format("truetype");
  font-weight: 400;
}