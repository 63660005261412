.error-modal {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 10;

  .background {
    position: absolute !important;
    background-color: black;
    opacity: 0.5 !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__inner {
    position: absolute;
    height: 100%;
    max-height: 250px;
    width: 80%;
    left: 10%;
    top: calc(50% - 100px);
    max-width: 500px;
    padding: 12px 24px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: $color--modal;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);

    @include breakpoint(medium) {
      max-width: 500px;
      left: calc(50% - 250px);
      top: calc(50% - 100px);
      padding: 12px 24px;
    }

    .form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h4 {
        margin-bottom: 20px;
      }

      p {
        margin: 0;
      }
    }

    img {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 35px;
      width: 35px;
    }

    // button {
    //   margin-top: 16px;
    //   color: white;
    //   width: -moz-fit-content;
    //   width: fit-content;
    //   padding: 12px 24px;
    //   border-radius: 25px;
    //   transition: 0.3s ease-in;
    //   font-size: 16px;
    //   text-transform: uppercase;
    //   border: none;
    // }
  }
}