.verify-email {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__inner {
    width: 100%;
    height: 360px;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px;

    h2 {
      margin-bottom: 10px;
    }
  }

  .verify-email-form {
    width: 100%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 60px 60px;

    .form-control {
      width: 100%;
      max-width: unset;

      input {
        width: 100%;
        max-width: unset;
        border-radius: 5px;
      }
    }

    // button {
    //   margin-top: 0;
    //   box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    // }
  }
}