.banner {
  position: relative;
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  padding: 0 16px;

  @include breakpoint (medium) {
    background-position: top;
    background-size: cover;
  }

  @include breakpoint (large) {
    background-position: bottom;
    background-size: cover;
  }


  &-background {
    width: 100vw;
    height: 100dvh;
    background-repeat: no-repeat;
    background-image: url('../../../assets/images/recruiter.jpeg');
    display: flex;
    position: absolute;
    z-index: 1;
    justify-content: center;
    background-size: cover;
    background-position: center;
    opacity: 0.39;

    @include breakpoint (large) {
      background-size: 120vw;
      background-position: left;
    }
  }


  &__inner {
    z-index: 2;
    text-align: center;
    font-weight: 100;
    color: $color--white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include breakpoint (medium) {
      width: 70%;
    }

    @include breakpoint (large) {
      width: 54%;
    }

    h2 {
      font-size: 30px;
      line-height: 38px;
      letter-spacing: 2px;
      font-family: $font-header;
      text-transform: uppercase;
      margin-bottom: 20px;

      @include breakpoint (small) {
        font-size: 26px;
        line-height: 40px;
        margin-bottom: 12px;
      }

      @include breakpoint (medium) {
        font-size: 26px;
        line-height: 44px;
      }
    }

    h3 {
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 20px;
      font-family: $font-subheader;

      @include breakpoint (small) {}

      @include breakpoint (medium) {
        font-size: 48px;
        line-height: 44px;
      }
    }

    h4 {
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 40px;
      margin: 0;
      margin-bottom: 40px;
      font-family: $font-body;

      @include breakpoint (small) {}

      @include breakpoint (medium) {
        font-size: 30px;
        line-height: 30px;
      }
    }

    .call-to-action {
      margin-top: 0;
      color: white;
      width: -moz-fit-content;
      width: fit-content;
      padding: 12px 24px;
      border-radius: 25px;
      transition: 0.3s ease-in;
      font-size: 16px;
      text-transform: uppercase;
      border: none;
      background: $color--purple;

      &:active {
        transform: scale(0.9);
      }

      &:focus {
        outline: none;
      }
    }
  }
}