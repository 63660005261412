.uploadBox {
    padding: 2em;
    border: 2px dashed gray;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.uploadBoxActive {
    padding: 2em;
    border: 2px dashed darkgray;
    background-color: lightgray;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.drag {
    font-weight: 600;
}

.drag {
    margin-top: 1em;
    margin-bottom: 0.5em;
    cursor: pointer;
}

.drag .browse {
    display: inline-block;
    color: orange;
}

.fileLabel {
    cursor: pointer;
}

.fileIcon {
    display: none;
}

.info {
    font-size: 12px;
}