.canvas {
  position: absolute !important;
  height: 34vh !important;
  width: 100vw;
  margin-top: -76vh;
  z-index: 5;
  @include breakpoint (medium) {
    margin-top: -67vh;
    height: 50vh !important;
    width: 60vw;
  }

  div {
    height: inherit;
    width: 100vw !important;
    @include breakpoint (medium) {
      margin-left: -20vw;
      width: 40vw;
    }

    canvas {
      height: inherit !important;
    }
  }
}