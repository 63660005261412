.off-canvas {
  opacity: 1;
  visibility: hidden;
  transition: 0.3s ease-in-out;
  background: $color--modal;
  position: fixed;
  padding: 0 16px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 14;
  transform: translateX(-100%);

  @include breakpoint(medium) {
    width: 640px;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    overflow-y: scroll;
    transform: translateX(0);
  }

  &__nav {
    display: flex;
    flex-direction: column;
    margin-top: 120px;

    a {
      &:nth-of-type(1) {
        animation-delay: 150ms !important;
      }

      &:nth-of-type(2) {
        animation-delay: 300ms !important;
      }

      &:nth-of-type(3) {
        animation-delay: 450ms !important;
      }

      &:nth-of-type(4) {
        animation-delay: 600ms !important;
      }
    }

    a {
      width: fit-content;
      color: $color--white;
      @include hoverline($color--white, 1px);
      margin-bottom: 50px;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 20px;
    }
  }
}

.off-canvas__nav--color {
  a {
    width: fit-content;
    color: $color--secondary;
    @include hoverline($color--secondary, 1px);
    margin-bottom: 20px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
  }
}