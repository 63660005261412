.header {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  display: flex;
  height: 80px;
  background: transparent;
  transition: ease 0.6s;

  @include breakpoint(medium) {
    height: 120px;
  }

  &--scrolled {
    top: 0px;
    height: 60px;

    .header__inner {
      .hamburger {
        background: transparent !important;

        .lines {
          background: $color--black;

          &::before,
          &::after {
            background: $color--black;
          }
        }
      }
    }
  }



  &--hidden {
    top: -500px;
  }

  &__inner {
    max-width: $content-width;
    width: 100%;
    padding: 0 16px;
    display: flex;
    height: inherit;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    a {
      svg {
        width: 30px;
        height: 30px;
        margin-top: 5px;

        @include breakpoint(medium) {
          width: 40px;
          height: 40px;
        }
      }
    }

    img {
      width: 130px;
      height: 60px;
      margin-bottom: 0;
      object-fit: cover;
    }

    nav {
      display: none;

      @include breakpoint (medium) {
        width: 46.5%;
        display: flex;
        justify-content: space-between;
      }

      .nav-button {
        color: $color--white;
        font-family: $font-subheader;
        @include hoverline($color--white, 1px);
        cursor: pointer;
        font-size: 18px;

        &--color {
          color: $color--white;
        }
      }

      .socials {
        display: none;

        @include breakpoint (medium) {
          display: flex;
          justify-content: space-between;
          display: none;
        }

        a {
          @include hoverline(transparent, 1px);
          cursor: pointer;
          align-items: center;
          display: flex;
          margin-right: 10px;

          img {
            margin-bottom: 0;
            height: 20px;
            width: auto;
          }
        }
      }
    }


    .header--color {
      a {
        color: $color--black;
      }
    }

    .hamburger {
      background: transparent !important;
      border: none;
      outline: none;
      height: 30px;
      padding: 0;
      cursor: pointer;
      box-shadow: none;

      @include breakpoint (medium) {
        display: none;
      }

      @mixin line {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 2px;
        top: -1px;
        right: 0;
        background: $color--white;
        border-radius: 0;
        transition: 0.2s;
      }

      .lines {
        //create middle line
        @include line;
        position: relative;
        color: $color--white;

        &::before,
        &::after {
          @include line;
          background: $color--white;
          position: absolute;
          content: "";
          transform-origin: 35px/14 center;
          left: 0;
          z-index: 30;
        }

        &::before {
          top: -5px;
          background: $color--white;
          height: 2px;
          z-index: 30;
        }

        &::after {
          top: 5px;
          background: $color--white;
          height: 2px;
          z-index: 30;
        }
      }

      &.active {
        .lines {
          background: transparent;
          border: 0;

          &:before,
          &:after {
            transform-origin: 50% 50%;
            top: 0px;
            width: 22px;
            position: absolute;
            z-index: 30;
          }

          &:before {
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}