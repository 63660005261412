/*********************
BREAKPOINTS
 *********************/

@mixin breakpoint($point) {
  @if $point ==x-large {
    @media (min-width: 1800px) {
      @content;
    }
  }

  @if $point ==large {
    @media (min-width: 1240px) {
      @content;
    }
  }

  @else if $point ==medium-large {
    @media (min-width: 1140px) {
      @content;
    }
  }

  @else if $point ==medium {
    @media (min-width: 800px) {
      @content;
    }
  }

  @else if $point ==small {
    @media (min-width: 600px) {
      @content;
    }
  }
}

@mixin container {
  padding: 0 16px;

  @include breakpoint (medium) {
    margin: 0 auto;
    max-width: $content-width;
    padding: 0 20px;
  }
}

@mixin content-list {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  li a {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
}

@mixin footer-heading {
  font-family: $font-header;
  font-size: 20px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 25px;
}

@mixin footer-text {
  font-family: $font-header;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0.12px;
  line-height: 24px;
}

@mixin hoverline($color, $height: 2px) {
  display: inline-block;
  position: relative;
  color: $color;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: $height;
    bottom: -2px;
    left: 0;
    background-color: $color;
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &.active::after {
    transform: scaleX(1);
  }
}

@mixin picture {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 66%;
  margin: 0;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin: 0;
  }

}

@mixin plyr($color: #64656A) {
  .plyr__control--overlaid {
    background: rgba($color, 0.8);
  }

  input[type=range] {
    color: $color;
  }

  .plyr__control.plyr__tab-focus {
    box-shadow: 0 0 0 5px rgba($color, 0.5);
  }

  .plyr__control.plyr__tab-focus,
  .plyr__control:hover,
  .plyr__control[aria-expanded=true] {
    background: $color;
  }
}

@mixin corner($background: #000, $foreground: #fff, $corner: 40px, $transition: 0.5s) {
  &::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0px;
    bottom: 0px;
    background: $foreground;
    transition: $transition;
    z-index: 1;
  }

  &::after {
    content: '';
    z-index: 1;
    display: block;
    position: absolute;

    width: 0;
    height: 0;
    left: 0;
    bottom: 0;
    background: $background;
    transform: rotate(-45deg);
    transition: $transition;
  }

  &:hover {
    &::before {
      width: $corner;
      height: $corner;
    }

    &::after {
      width: $corner*1.4;
      height: $corner*1.4;
      left: $corner*-0.7;
      bottom: $corner*-0.7;
    }
  }
}