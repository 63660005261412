::-moz-selection {
  /* Code for Firefox */
  color: $color--white;
  background: $color--black;
}

::selection {
  color: $color--white;
  background: $color--black;
}

body {
  width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  background: #7D7976;
  background: $color--dark;
  font-weight: 300;
  color: $color--white;
  letter-spacing: 2px;

  &.menu-open {
    overflow: hidden;

    &:after {
      transform: translateX(-240px);
    }
  }

  #___gatsby {
    width: 100vw;
    overflow-x: hidden;
  }
}

h1,
h2 {
  font-family: $font-header !important;
  word-spacing: 3px;
  letter-spacing: 0.15px;
  font-weight: 100;
  color: $color--white;
  font-size: 26px;
  margin-bottom: 12px;

  @include breakpoint(small) {
    font-size: unset;
  }
}

h3,
h4,
h5,
h6 {
  font-family: $font-header !important;
  letter-spacing: 0.15px;
  font-weight: 100;
  color: $color--white;
}

h1 {
  font-size: 35px;
  line-height: 40px;
  font-weight: bold;
}

h2 {
  font-size: 28px;
  line-height: 38px;

  @include breakpoint(medium) {
    font-size: 25px;
    line-height: 30px;
  }
}

h3 {
  letter-spacing: 0.3px;
  font-size: 22px;
  line-height: 30px;
}

p,
ul,
li,
ol,
div,
table {
  font-family: $font-body;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.16px;
  line-height: 25px;
}

blockquote,
blockquote p {
  font-style: italic;
  font-size: 25px;
  line-height: 40px;
  font-family: $font-header;
}

a {
  color: $color--grey;
  font-family: $font-body;
  text-decoration: none;
  color: $color--white;
}

.error-404 {
  text-align: center;
  padding: 100px 0;
}

button {
  background: $color--purple !important;
  color: $color--white;
  width: fit-content;
  padding: 16px 32px;
  border-radius: 30px;
  transition: 0.3s ease-in-out;
  text-transform: capitalize;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  font-weight: bold;
  letter-spacing: 1.1px;

  &:hover {
    transform: scale(1.1);
  }


  &:active {
    transform: scale(0.9);
  }

  &:focus {
    outline: none;
  }
}

label {
  color: $color--white;
}