.formatter {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  background: $color--modal;

  @include breakpoint (medium) {
    background-position: top;
    background-size: cover;
    height: 100vh;
    background: unset;
  }

  @include breakpoint (large) {
    background-position: bottom;
    background-size: cover;
  }


  &__inner {
    z-index: 2;
    text-align: center;
    font-weight: 100;
    margin-top: 100px;
    color: $color--white;
    width: 90%;
    max-width: $content-width;
    border-radius: 10px;
    background: $color--modal;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    height: calc(100dvh - 100px);

    @include breakpoint (medium) {
      margin-top: 50px;
      justify-content: center;
      align-items: center;
      padding: 12px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
      height: unset;
    }

    h2 {
      font-size: 20px;
      line-height: 36px;
      letter-spacing: 3.5px;
      font-family: $font-body;
      font-weight: bold;
      padding-bottom: 4px;

      @include breakpoint (small) {
        margin-bottom: 12px;
      }

      @include breakpoint (medium) {}
    }
  }


  &__heading {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 20px 0px 10px;
    width: 100%;
    max-width: 900px;
    height: 120px;
    margin-bottom: 40px;


    @include breakpoint(small) {
      height: 140px;
      margin-bottom: unset;
    }

    h2 {
      color: $color--white;
      margin-bottom: 20px;

      @include breakpoint(small) {
        margin-bottom: 40px;
      }
    }
  }

  .steps {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-evenly;
    height: 100px;
    width: 100%;
    margin-bottom: 30px;
    align-items: center;

    @include breakpoint (small) {}

    @include breakpoint(medium-large) {
      display: flex;
      width: 100%;
      max-width: 110%;
      width: 100%;
    }

    @include breakpoint(large) {
      width: 110%;
    }
  }



  .progress-line {
    position: absolute;
    height: 5px;
    background: grey;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    width: 100%;
  }

  .progress-line- {
    position: absolute;
    height: 5px;
    background: grey;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    width: 100%;
    transition: ease 0.7s;

    &role-step {
      position: absolute;
      width: 20%;
      height: 5px;
      background: $color--purple;
      z-index: 0;
      left: 0;
      transition: ease 0.7s;
    }

    &template-step {
      position: absolute;
      width: 40%;
      height: 5px;
      background: $color--purple;
      z-index: 0;
      left: 0;
      transition: ease 0.7s;
    }

    &resume-step {
      position: absolute;
      width: 60%;
      height: 5px;
      background: $color--purple;
      z-index: 0;
      left: 0;
      transition: ease 0.7s;
    }

    &download-step {
      position: absolute;
      width: 100%;
      height: 5px;
      background: $color--purple;
      z-index: 0;
      left: 0;
      transition: ease 0.7s;
    }
  }

  .line {
    position: relative;
    background: $color--grey-white-gradient;

    @include breakpoint(small) {
      width: 100%;
      height: 6px;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }

    .animation-line {
      width: 0%;
      height: 6px;
      background: $color--purple;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: ease 0.7s;
      z-index: 10;

      &.active {
        width: 100%;
      }

      &:last-child {
        transition: ease 1.4s;
      }
    }
  }

  .line-final {
    position: relative;
    animation-delay: 1s;

    @include breakpoint(small) {
      width: 100%;
      height: 6px;
      background: $color--grey-white-gradient;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }


    .animation-line {
      width: 0%;
      height: 6px;
      background: $color--grey-white-gradient;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: ease 0.7s;
      z-index: 100;
      animation-delay: 1s;

      &.active {
        width: 100%;
        animation-delay: 1s;
        transition-delay: 1s;
      }
    }
  }

  .step {
    margin: 2px;
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    flex-direction: column;

    .img-div {
      height: 46px;
      width: 46px;
      border-radius: 23px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: grey;
      transition: ease 1s;

      &.active {
        background: $color--purple !important;
      }

      &.selected {
        background: $color--green-purple-gradient;
      }
    }

    .text-div {
      position: absolute;
      bottom: -30px;
      width: calc(180px - 60px);
    }

    img {
      width: 25px;
      height: auto;
      padding: 4px;
      margin: 0px;
      height: 34px;
    }

    h5 {
      font-family: $font-header;
      margin: 0px;
      margin-top: 10px;
      color: $color--white;
    }
  }

  img {
    width: 20px;
    height: auto;
    padding: 4px;
    margin: 0px;
  }

  .card-div {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    background-color: transparent;
    border-radius: 10px 10px 0px 0px;

    @include breakpoint (small) {
      max-width: 900px;
      padding: 10px 0px;
    }

    .nav {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 250px;
      height: 50px;
      margin-bottom: 20px;


      .dot {
        height: 15px;
        width: 15px;
        background: #DDDFE6;
        border-radius: 20px;

        &.active {
          transition: 0.3s ease-in;
          background: $color--purple;
        }

        &.selected {
          transition: 0.3s ease-in;
        }
      }
    }

    .button-container.row {
      width: 100%;

      @include breakpoint(large) {
        width: 110%;
      }

      .col-md-auto {
        padding: 0;
        margin: 0;
        width: auto;


        button {
          margin: 0;
        }
      }
    }
  }
}